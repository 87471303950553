export const Types = {
  GET_CEG_REQUEST: 'GET_CEG_REQUEST',
  GET_CEG_SUCCESS: 'GET_CEG_SUCCESS',
  GET_CEG_FAIL: 'GET_CEG_FAIL',

  GET_CEG_LIST: 'GET_CEG_LIST',
  GET_CEG_LIST_SUCCESS: 'GET_CEG_LIST_SUCCESS',
  GET_CEG_LIST_ERROR: 'GET_CEG_LIST_ERROR',

  CREATE_CEG_REQUEST: 'CREATE_CEG_REQUEST',
  CREATE_CEG_SUCCESS: 'CREATE_CEG_SUCCESS',
  CREATE_CEG_FAIL: 'CREATE_CEG_FAIL',

};

const INIT_STATE = {
  loading: false,
  error: false,
  data: null,

  createLoading: false,
  createSuccess: false,
  createError: false,

  cegList: [],
  cegListLoading: false,
  cegListError: false,

  amount: 10,
};

export default function Ceg(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CEG_REQUEST:
      return {
        loading: true,
        error: false,
      };
    case Types.GET_CEG_SUCCESS:
      return {
        loading: false,
        error: false,
        data: action.payload.data,
        amount: action.payload.total,
      };
    case Types.GET_CEG_FAIL:
      return {
        loading: false,
        error: true,
      };
    case Types.GET_CEG_LIST:
      return {
        ...state,
        cegList: [],
        cegListLoading: true,
        cegListError: false,
      };
    case Types.GET_CEG_LIST_SUCCESS:
      return {
        ...state,
        cegList: action.payload,
        cegListLoading: false,
        cegListError: false,
      };
    case Types.GET_CEG_LIST_ERROR:
      return {
        ...state,
        cegList: [],
        cegListLoading: false,
        cegListError: true,
      };
    case Types.CREATE_CEG_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createError: false,
      };
    case Types.CREATE_CEG_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createError: false,
      };
    case Types.CREATE_CEG_FAIL:
      return {
        ...state,
        createLoading: false,
        createSuccess: false,
        createError: true,
      };
    default:
      return state;
  }
}

export const Creators = {
  getCegRequest: payload => ({
    type: Types.GET_CEG_REQUEST,
    payload,
  }),
  getCegSuccess: payload => ({
    type: Types.GET_CEG_SUCCESS,
    payload,
  }),
  getCegFail: () => ({
    type: Types.GET_CEG_FAIL,
  }),
  getCegList: () => ({
    type: Types.GET_CEG_LIST,
  }),
  getCegListSuccess: payload => ({
    type: Types.GET_CEG_LIST_SUCCESS,
    payload,
  }),
  getCegListError: () => ({
    type: Types.GET_CEG_LIST_ERROR,
  }),
  createCegRequest: payload => ({
    type: Types.CREATE_CEG_REQUEST,
    payload
  }),
  createCegSuccess: payload => ({
    type: Types.CREATE_CEG_SUCCESS,
    payload,
  }),
  createCegFail: () => ({
    type: Types.CREATE_CEG_FAIL,
  }),
};
