import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ParticipantDashboardTypes,
  Creators as ParticipantDashboardActions,
} from '../ducks/ParticipantDashboard';
import api from '../../util/Api';

function* getParticipantTopNumbers() {
  try {
    const { status, data } = yield call(api.get, '/participantDashboard/topNumbers');
    if (status === 200) {
      yield put(
        ParticipantDashboardActions.getParticipantTopNumbersSuccess(data)
      );
    } else {
      yield put(
        ParticipantDashboardActions.getParticipantTopNumbersError(
          'Error listing topnumbers'
        )
      );
    }
  } catch {
    yield put(
      ParticipantDashboardActions.getParticipantTopNumbersError(
        'Error listing topnumbers'
      )
    );
  }
}

function* getParticipantTopNumbersWatcher() {
  yield takeLatest(
    ParticipantDashboardTypes.GET_PARTICIPANT_TOP_NUMBERS,
    getParticipantTopNumbers
  );
}


export default function* rootSaga() {
  yield all([
    fork(getParticipantTopNumbersWatcher),
  ]);
}
