import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as CegActions, Types as CegTypes } from 'store/ducks/Ceg';
import api from '../../util/Api';

function* getCeg({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/ceg', {
      params: payload,
    });
    if (status === 200) {
      yield put(CegActions.getCegSuccess(data));
    } else {
      yield put(CegActions.getCegFail());
    }
  } catch (err) {
    yield put(CegActions.getCegFail());
  }
}

function* getListCeg() {
  try {
    const { status, data } = yield call(api.get, '/ceg/list');
    if (status === 200) {
      yield put(CegActions.getCegListSuccess(data));
    } else {
      yield put(CegActions.getCegListError());
    }
  } catch (err) {
    yield put(CegActions.getCegListError());
  }
}

function* createCeg({ payload }) {
  try {
    const { status } = yield call(api.post, '/ceg', payload);
    if (status === 200) {
      yield put(CegActions.createCegSuccess());
    } else {
      yield put(CegActions.createCegFail());
    }
  } catch (e) {
    yield put(CegActions.createCegFail());
  }
}

function* getCegWatcher() {
  yield takeLatest(CegTypes.GET_CEG_REQUEST, getCeg);
}

function* getCegListWatcher() {
  yield takeLatest(CegTypes.GET_CEG_LIST, getListCeg);
}

function* getCegCreateWatcher() {
  yield takeLatest(CegTypes.CREATE_CEG_REQUEST, createCeg);
}

export default function* rootSaga() {
  yield all([fork(getCegWatcher), fork(getCegListWatcher), fork(getCegCreateWatcher)]);
}
