import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as GasrecTypes,
  Creators as RecTransferActions,
} from '../ducks/RecTransfer';
import api from '../../util/Api';

function* getGasrecs({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/transation', {
      params: payload,
    });
    if (status === 200) {
      yield put(RecTransferActions.getGasrecsSuccess(data));
    }
  } catch (error) {
    yield put(
      RecTransferActions.getGasrecsError('Error listing Gasrecs')
    );
  }
}


function* getGasrecById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/transation/list`, {
      params:payload
    });
    if (status === 200) {
      yield put(RecTransferActions.getGasrecByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      RecTransferActions.getGasrecByIdError('gasrec not found')
      );
    }
  }

  function* postGasrec({ payload }) {
    try {
      const { status } = yield call(api.post, '/transation/transferParticipant', payload, {
        headers: {
          ...(payload instanceof FormData
            ? { 'Content-Type': 'multipart/form-data' }
            : {}),
        },
      });
      if (status === 200) {
        yield put(RecTransferActions.postGasrecSuccess());
      }
    } catch (error) {
      yield put(RecTransferActions.postGasrecError(error?.response?.data));
    }
  }

function* getGasrecsWatcher() {
  yield takeLatest(GasrecTypes. GET_GASRECS_PARTICIPANTS, getGasrecs);
}

function* postGasrecWatcher() {
  yield takeLatest(GasrecTypes.POST_GASRECS_PARTICIPANTS, postGasrec);
}

function* getGasrecByIdWatcher() {
  yield takeLatest(GasrecTypes.GET_GASREC_PARTICIPANT_BY_ID, getGasrecById);
}

export default function* rootSaga() {
  yield all([
    fork(getGasrecsWatcher),
    fork(postGasrecWatcher),
    fork(getGasrecByIdWatcher),
  ]);
}
