export const Types = {
  GET_LIST_PARTICIPANT_REQUEST: 'GET_LIST_PARTICIPANT_REQUEST',
  GET_LIST_PARTICIPANT_SUCCESS: 'GET_LIST_PARTICIPANT_SUCCESS',
  GET_LIST_PARTICIPANT_FAIL: 'GET_LIST_PARTICIPANT_FAIL',

  DELETE_PARTICIPANT_REQUEST: 'DELETE_PARTICIPANT_REQUEST',
  DELETE_PARTICIPANT_SUCCESS: 'DELETE_PARTICIPANT_SUCCESS',
  DELETE_PARTICIPANT_FAIL: 'DELETE_PARTICIPANT_FAIL',
  DELETE_PARTICIPANT_CLEAN: 'DELETE_PARTICIPANT_CLEAN',

  GET_ID_PARTICIPANT_REQUEST: 'GET_ID_PARTICIPANT_REQUEST',
  GET_ID_PARTICIPANT_SUCCESS: 'GET_ID_PARTICIPANT_SUCCESS',
  GET_ID_PARTICIPANT_FAIL: 'GET_ID_PARTICIPANT_FAIL',

  CREATE_PARTICIPANT_REQUEST: 'CREATE_PARTICIPANT_REQUEST',
  CREATE_PARTICIPANT_SUCCESS: 'CREATE_PARTICIPANT_SUCCESS',
  CREATE_PARTICIPANT_FAIL: 'CREATE_PARTICIPANT_FAIL',
  CREATE_PARTICIPANT_CLEAN: 'CREATE_PARTICIPANT_CLEAN',

  UPDATE_PARTICIPANT_REQUEST: 'UPDATE_PARTICIPANT_REQUEST',
  UPDATE_PARTICIPANT_SUCCESS: 'UPDATE_PARTICIPANT_SUCCESS',
  UPDATE_PARTICIPANT_FAIL: 'UPDATE_PARTICIPANT_FAIL',
  UPDATE_PARTICIPANT_CLEAN: 'UPDATE_PARTICIPANT_CLEAN',
};

const INIT_STATE = {
  amount: 10,

  data: null,
  loading: false,
  error: false,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  idLoading: false,
  idData: null,
  idError: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

export default function Participant(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_LIST_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_LIST_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_LIST_PARTICIPANT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_PARTICIPANT_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_PARTICIPANT_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_PARTICIPANT_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.GET_ID_PARTICIPANT_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_PARTICIPANT_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_PARTICIPANT_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };

    case Types.CREATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_PARTICIPANT_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_PARTICIPANT_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.UPDATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_PARTICIPANT_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_PARTICIPANT_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getListParticipantRequest: payload => ({
    type: Types.GET_LIST_PARTICIPANT_REQUEST,
    payload,
  }),
  getListParticipantSuccess: payload => ({
    type: Types.GET_LIST_PARTICIPANT_SUCCESS,
    payload,
  }),
  getListParticipantFail: () => ({
    type: Types.GET_LIST_PARTICIPANT_FAIL,
  }),

  // DELETE
  deleteParticipantRequest: payload => ({
    type: Types.DELETE_PARTICIPANT_REQUEST,
    payload,
  }),
  deleteParticipantSuccess: () => ({
    type: Types.DELETE_PARTICIPANT_SUCCESS,
  }),
  deleteParticipantFail: () => ({
    type: Types.DELETE_PARTICIPANT_FAIL,
  }),
  deleteParticipantClean: () => ({
    type: Types.DELETE_PARTICIPANT_CLEAN,
  }),

  // ID
  getIdParticipantRequest: payload => ({
    type: Types.GET_ID_PARTICIPANT_REQUEST,
    payload,
  }),
  getIdParticipantSuccess: payload => ({
    type: Types.GET_ID_PARTICIPANT_SUCCESS,
    payload,
  }),
  getIdParticipantFail: () => ({
    type: Types.GET_ID_PARTICIPANT_FAIL,
  }),

  // CREATE
  createParticipantRequest: payload => ({
    type: Types.CREATE_PARTICIPANT_REQUEST,
    payload,
  }),
  createParticipantSuccess: () => ({
    type: Types.CREATE_PARTICIPANT_SUCCESS,
  }),
  createParticipantFail: () => ({
    type: Types.CREATE_PARTICIPANT_FAIL,
  }),
  createParticipantClean: () => ({
    type: Types.CREATE_PARTICIPANT_CLEAN,
  }),

  // UPDATE
  updateParticipantRequest: payload => ({
    type: Types.UPDATE_PARTICIPANT_REQUEST,
    payload,
  }),
  updateParticipantSuccess: payload => ({
    type: Types.UPDATE_PARTICIPANT_SUCCESS,
    payload,
  }),
  updateParticipantFail: () => ({
    type: Types.UPDATE_PARTICIPANT_FAIL,
  }),
  updateParticipantClean: () => ({
    type: Types.UPDATE_PARTICIPANT_CLEAN,
  }),
};
