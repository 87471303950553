import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as AdminDashboardTypes,
  Creators as AdminDashboardActions,
} from '../ducks/AdminDashboard';
import api from '../../util/Api';

function* getTopNumbers() {
  try {
    const { status, data } = yield call(api.get, '/adminDashboard/topNumbers');
    if (status === 200) {
      yield put(AdminDashboardActions.getTopNumbersSuccess(data));
    } else {
      yield put(
        AdminDashboardActions.getTopNumbersError('Error listing topnumbers')
      );
    }
  } catch {
    yield put(
      AdminDashboardActions.getTopNumbersError('Error listing topnumbers')
    );
  }
}
function* getMwhByYear() {
  try {
    const { status, data } = yield call(
      api.get,
      '/adminDashboard/countedMwhByYear'
    );
    if (status === 200) {
      yield put(AdminDashboardActions.getMwhByYearSuccess(data));
    } else {
      yield put(
        AdminDashboardActions.getMwhByYearError(
          "Error listing mwh's by year"
        )
      );
    }
  } catch {
    yield put(
      AdminDashboardActions.getMwhByYearError("Error listing mwh's by year")
    );
  }
}

function* getBusinessByTechnology() {
  try {
    const { status, data } = yield call(
      api.get,
      '/adminDashboard/businessesByThechnology'
    );
    if (status === 200) {
      yield put(AdminDashboardActions.getBusinessByTechnologySuccess({ data }));
    } else {
      yield put(
        AdminDashboardActions.getBusinessByTechnologyError(
          'Error listing projects by technology'
        )
      );
    }
  } catch {
    yield put(
      AdminDashboardActions.getBusinessByTechnologyError(
        'Error listing projects by technology'
      )
    );
  }
}

function* getApprovedRecsByEmission() {
  try {
    const { status, data } = yield call(
      api.get,
      '/adminDashboard/approvedRecsByEmissionMonth'
    );
    if (status === 200) {
      yield put(AdminDashboardActions.getApprovedRecsByEmissionSuccess(data));
    } else {
      yield put(
        AdminDashboardActions.getApprovedRecsByEmissionError(
          'Error listing approved recs by issue date'
        )
      );
    }
  } catch {
    yield put(
      AdminDashboardActions.getApprovedRecsByEmissionError(
        'Error listing approved recs by issue date'
      )
    );
  }
}

function* getApprovedRecsByProduction() {
  try {
    const { status, data } = yield call(
      api.get,
      '/adminDashboard/approvedRecsByProductionMonth'
    );
    if (status === 200) {
      yield put(AdminDashboardActions.getApprovedRecsByProductionSuccess(data));
    } else {
      yield put(
        AdminDashboardActions.getApprovedRecsByProductionError(
          'Error listing approved recs by production date'
        )
      );
    }
  } catch {
    yield put(
      AdminDashboardActions.getApprovedRecsByProductionError(
        'Error listing approved recs by production date'
      )
    );
  }
}

function* getTopNumbersWatcher() {
  yield takeLatest(AdminDashboardTypes.GET_TOP_NUMBERS, getTopNumbers);
}

function* getMwhByYearWatcher() {
  yield takeLatest(AdminDashboardTypes.GET_MWH_BY_YEAR, getMwhByYear);
}

function* getBusinessByTechnologyWatcher() {
  yield takeLatest(
    AdminDashboardTypes.GET_BUSINESS_BY_TECHNOLOGY,
    getBusinessByTechnology
  );
}

function* getApprovedRecsByEmissionWatcher() {
  yield takeLatest(
    AdminDashboardTypes.GET_APPROVED_RECS_BY_EMISSION,
    getApprovedRecsByEmission
  );
}

function* getApprovedRecsByProductionWatcher() {
  yield takeLatest(
    AdminDashboardTypes.GET_APPROVED_RECS_BY_PRODUCTION,
    getApprovedRecsByProduction
  );
}

export default function* rootSaga() {
  yield all([
    fork(getTopNumbersWatcher),
    fork(getMwhByYearWatcher),
    fork(getBusinessByTechnologyWatcher),
    fork(getApprovedRecsByEmissionWatcher),
    fork(getApprovedRecsByProductionWatcher),
  ]);
}
