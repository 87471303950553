export const Types = {
  EXPORT_FILE_REQUEST: 'EXPORT_FILE_REQUEST',
  EXPORT_FILE_SUCCESS: 'EXPORT_FILE_SUCCESS',
  EXPORT_FILE_ERROR: 'EXPORT_FILE_ERROR',
  EXPORT_FILE_RESET: 'EXPORT_FILE_RESET',

  IMPORT_FILE_REQUEST: 'IMPORT_FILE_REQUEST',
  IMPORT_FILE_SUCCESS: 'IMPORT_FILE_SUCCESS',
  IMPORT_FILE_ERROR: 'IMPORT_FILE_ERROR',
  IMPORT_FILE_RESET: 'IMPORT_FILE_RESET',
};

const INIT_STATE = {
  exportLoading: false,
  file: null,
  exportError: false,

  importLoading: false,
  importError: null,
  importSuccess: false,
};

export default function File(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.EXPORT_FILE_REQUEST:
      return {
        exportLoading: true,
        file: null,
        exportError: false,
      };
    case Types.EXPORT_FILE_SUCCESS:
      return {
        exportLoading: false,
        file: action.payload,
        exportError: false,
      };
    case Types.EXPORT_FILE_ERROR:
      return {
        exportLoading: false,
        file: null,
        exportError: true,
      };
    case Types.EXPORT_FILE_RESET:
      return {
        exportLoading: false,
        file: null,
        exportError: false,
      };

    case Types.IMPORT_FILE_REQUEST:
      return {
        importLoading: true,
        importError: false,
        importSuccess: false,
      };
    case Types.IMPORT_FILE_SUCCESS:
      return {
        importLoading: false,
        importError: false,
        importSuccess: true,
      };
    case Types.IMPORT_FILE_ERROR:
      return {
        importLoading: false,
        importError: true,
        importSuccess: false,
      };
    case Types.IMPORT_FILE_RESET:
      return {
        importLoading: false,
        importError: false,
        importSuccess: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  exportFileRequest: payload => ({
    type: Types.EXPORT_FILE_REQUEST,
    payload,
  }),
  exportFileSuccess: payload => ({
    type: Types.EXPORT_FILE_SUCCESS,
    payload,
  }),
  exportFileError: () => ({
    type: Types.EXPORT_FILE_ERROR,
  }),
  exportFileReset: () => ({
    type: Types.EXPORT_FILE_RESET,
  }),
  importFileRequest: payload => ({
    type: Types.IMPORT_FILE_REQUEST,
    payload,
  }),
  importFileSuccess: () => ({
    type: Types.IMPORT_FILE_SUCCESS,
  }),
  importFileError: () => ({
    type: Types.IMPORT_FILE_ERROR,
  }),
  importFileReset: () => ({
    type: Types.IMPORT_FILE_RESET,
  }),
};
