import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as IssuerActions,
  Types as IssuerTypes,
} from 'store/ducks/Issuer';
import api from '../../util/Api';

function* getList({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/issuer', {
      params: payload,
    });

    if (status === 200) {
      yield put(IssuerActions.getListIssuerSuccess(data));
    } else {
      yield put(IssuerActions.getListIssuerFail());
    }
  } catch (err) {
    yield put(IssuerActions.getListIssuerFail());
  }
}

function* deleteIssuer({ payload }) {
  try {
    const { status } = yield call(api.delete, `/issuer/${payload}`);
    if (status === 200) {
      yield put(IssuerActions.deleteIssuerSuccess());
    } else {
      yield put(IssuerActions.deleteIssuerFail());
    }
  } catch (err) {
    yield put(IssuerActions.deleteIssuerFail());
  }
}

function* getIdIssuer({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/issuer/${payload}`);
    if (status === 200) {
      yield put(IssuerActions.getIdIssuerSuccess(data));
    } else {
      yield put(IssuerActions.getIdIssuerFail());
    }
  } catch (err) {
    yield put(IssuerActions.getIdIssuerFail());
  }
}

function* createIssuer({ payload }) {
  try {
    const { status } = yield call(api.post, '/issuer/create', payload, {
      headers: {
        ...(payload instanceof FormData
          ? { 'Content-Type': 'multipart/form-data' }
          : {}),
      },
    });

    if (status === 200 || status === 204) {
      yield put(IssuerActions.createIssuerSuccess());
    } else {
      yield put(IssuerActions.createIssuerFail());
    }

  } catch (e) {
    yield put(IssuerActions.createIssuerFail());
  }
}

function* updateIssuer({ payload }) {
  try {
    const { status } = yield call(api.put, `/issuer/${payload.id}`, payload.data
    );
    if (status === 200) {
      yield put(IssuerActions.updateIssuerSuccess());
    } else {
      yield put(IssuerActions.updateIssuerFail());
    }
  } catch (e) {
    yield put(IssuerActions.updateIssuerFail());
  }
}

function* getListWatcher() {
  yield takeLatest(IssuerTypes.GET_LIST_ISSUER_REQUEST, getList);
}

function* deleteIssuerWatcher() {
  yield takeLatest(
    IssuerTypes.DELETE_ISSUER_REQUEST,
    deleteIssuer
  );
}

function* getIdIssuerWatcher() {
  yield takeLatest(
    IssuerTypes.GET_ID_ISSUER_REQUEST,
    getIdIssuer
  );
}

function* createIssuerWatcher() {
  yield takeLatest(
    IssuerTypes.CREATE_ISSUER_REQUEST,
    createIssuer
  );
}

function* updateIssuerWatcher() {
  yield takeLatest(
    IssuerTypes.UPDATE_ISSUER_REQUEST,
    updateIssuer
  );
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(deleteIssuerWatcher),
    fork(getIdIssuerWatcher),
    fork(createIssuerWatcher),
    fork(updateIssuerWatcher),
  ]);
}
