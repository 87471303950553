export const Types = {
  GET_ENTERPRISES: 'GET_ENTERPRISES',
  GET_ENTERPRISES_SUCCESS: 'GET_ENTERPRISES_SUCCESS',
  GET_ENTERPRISES_ERROR: 'GET_ENTERPRISES_ERROR',
  POST_ENTERPRISE: 'POST_ENTERPRISE',
  POST_ENTERPRISE_SUCCESS: 'POST_ENTERPRISE_SUCCESS',
  POST_ENTERPRISE_ERROR: 'POST_ENTERPRISE_ERROR',
  GET_ENTERPRISE_BY_ID: 'GET_ENTERPRISE_BY_ID',
  GET_ENTERPRISE_BY_ID_SUCCESS: 'GET_ENTERPRISE_BY_ID_SUCCESS',
  GET_ENTERPRISE_BY_ID_ERROR: 'GET_ENTERPRISE_BY_ID_ERROR',
  PUT_ENTERPRISE: 'PUT_ENTERPRISE',
  PUT_ENTERPRISE_SUCCESS: 'PUT_ENTERPRISE_SUCCESS',
  PUT_ENTERPRISE_ERROR: 'PUT_ENTERPRISE_ERROR',
  DELETE_ENTERPRISE: 'DELETE_ENTERPRISE',
  DELETE_ENTERPRISE_SUCCESS: 'DELETE_ENTERPRISE_SUCCESS',
  DELETE_ENTERPRISE_ERROR: 'DELETE_ENTERPRISE_ERROR',
  APPROVE_ENTERPRISE: 'APPROVE_ENTERPRISE',
  APPROVE_ENTERPRISE_SUCCESS: 'APPROVE_ENTERPRISE_SUCCESS',
  APPROVE_ENTERPRISE_ERROR: 'APPROVE_ENTERPRISE_ERROR',
  APPROVE_ENTERPRISE_RESET: 'APPROVE_ENTERPRISE_RESET',
  REFUSE_ENTERPRISE: 'REFUSE_ENTERPRISE',
  REFUSE_ENTERPRISE_SUCCESS: 'REFUSE_ENTERPRISE_SUCCESS',
  REFUSE_ENTERPRISE_ERROR: 'REFUSE_ENTERPRISE_ERROR',
  REFUSE_ENTERPRISE_RESET: 'REFUSE_ENTERPRISE_RESET',
  ENTERPRISE_CLEAN: 'ENTERPRISE_CLEAN',
};

const INIT_STATE = {
  data: null,
  enterprise: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  amount: 10,
};

const ENTERPRISE_ERROR = {
  CEG_NOT_FOUND:
    'CEG not registered on the platform. Contact system administrator',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG already registered in another unit.',
};

export default function Enterprise(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_ENTERPRISES:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_ENTERPRISES_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_ENTERPRISES_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_ENTERPRISE:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_ENTERPRISE_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_ENTERPRISE_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error:
          ENTERPRISE_ERROR?.[action.payload?.message] ||
          action.payload?.message,
      };
    case Types.GET_ENTERPRISE_BY_ID:
      return {
        ...state,
        enterprise: null,
        loading: true,
        error: false,
      };
    case Types.GET_ENTERPRISE_BY_ID_SUCCESS:
      return {
        ...state,
        enterprise: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_ENTERPRISE_BY_ID_ERROR:
      return {
        ...state,
        enterprise: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_ENTERPRISE:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_ENTERPRISE_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_ENTERPRISE_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_ENTERPRISE:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_ENTERPRISE_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.ENTERPRISE_CLEAN:
      return {
        ...state,
        enterprise: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_ENTERPRISE:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_ENTERPRISE_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_ENTERPRISE_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_ENTERPRISE:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_ENTERPRISE_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_ENTERPRISE_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getEnterprises: payload => ({
    type: Types.GET_ENTERPRISES,
    payload,
  }),
  getEnterprisesSuccess: payload => ({
    type: Types.GET_ENTERPRISES_SUCCESS,
    payload,
  }),
  getEnterprisesError: payload => ({
    type: Types.GET_ENTERPRISES_ERROR,
    payload,
  }),
  postEnterprise: payload => ({
    type: Types.POST_ENTERPRISE,
    payload,
  }),
  postEnterpriseSuccess: payload => ({
    type: Types.POST_ENTERPRISE_SUCCESS,
    payload,
  }),
  postEnterpriseError: payload => ({
    type: Types.POST_ENTERPRISE_ERROR,
    payload,
  }),
  getEnterpriseById: payload => ({
    type: Types.GET_ENTERPRISE_BY_ID,
    payload,
  }),
  getEnterpriseByIdSuccess: payload => ({
    type: Types.GET_ENTERPRISE_BY_ID_SUCCESS,
    payload,
  }),
  getEnterpriseByIdError: payload => ({
    type: Types.GET_ENTERPRISE_BY_ID_ERROR,
    payload,
  }),
  putEnterprise: payload => ({
    type: Types.PUT_ENTERPRISE,
    payload,
  }),
  putEnterpriseSuccess: payload => ({
    type: Types.PUT_ENTERPRISE_SUCCESS,
    payload,
  }),
  putEnterpriseError: payload => ({
    type: Types.PUT_ENTERPRISE_ERROR,
    payload,
  }),
  deleteEnterprise: payload => ({
    type: Types.DELETE_ENTERPRISE,
    payload,
  }),
  deleteEnterpriseSuccess: payload => ({
    type: Types.DELETE_ENTERPRISE_SUCCESS,
    payload,
  }),
  deleteEnterpriseError: payload => ({
    type: Types.DELETE_ENTERPRISE_ERROR,
    payload,
  }),
  EnterpriseClean: () => ({
    type: Types.ENTERPRISE_CLEAN,
  }),
  approveEnterprise: payload => ({
    type: Types.APPROVE_ENTERPRISE,
    payload,
  }),
  approveEnterpriseSuccess: () => ({
    type: Types.APPROVE_ENTERPRISE_SUCCESS,
  }),
  approveEnterpriseError: payload => ({
    type: Types.APPROVE_ENTERPRISE_ERROR,
    payload,
  }),
  approveEnterpriseReset: () => ({
    type: Types.APPROVE_ENTERPRISE_RESET,
  }),
  refuseEnterprise: payload => ({
    type: Types.REFUSE_ENTERPRISE,
    payload,
  }),
  refuseEnterpriseSuccess: () => ({
    type: Types.REFUSE_ENTERPRISE_SUCCESS,
  }),
  refuseEnterpriseError: () => ({
    type: Types.REFUSE_ENTERPRISE_ERROR,
  }),
  refuseEnterpriseReset: () => ({
    type: Types.REFUSE_ENTERPRISE_RESET,
  }),
};
