import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as RegistrantTypes,
  Creators as RegistrantActions,
} from '../ducks/Registrant';
import api from '../../util/Api';

function* getRegistrants({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/registrants', {
      params: payload,
    });
    if (status === 200) {
      yield put(RegistrantActions.getRegistrantsSuccess(data));
    }
  } catch (error) {
    yield put(
      RegistrantActions.getRegistrantsError('Error listing registrants')
    );
  }
}

function* getRegistrantById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/registrants/${payload}`);
    if (status === 200) {
      yield put(RegistrantActions.getRegistrantByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      RegistrantActions.getRegistrantByIdError('Error listing registrant')
    );
  }
}

function* postRegistrant({ payload }) {
  try {
    const { status } = yield call(api.post, '/registrants', payload);
    if (status === 200) {
      yield put(RegistrantActions.postRegistrantSuccess());
    }
  } catch (error) {
    yield put(
      RegistrantActions.postRegistrantError('Error registering registrant')
    );
  }
}

function* putRegistrant({ payload }) {
  const { id } = payload;
  try {
    const { status } = yield call(api.put, `/registrants/${id}`, payload.data);
    if (status === 200) {
      yield put(RegistrantActions.putRegistrantSuccess());
    }
  } catch (error) {
    yield put(
      RegistrantActions.putRegistrantError('Error editing registrant')
    );
  }
}
function* deleteRegistrant({ payload }) {
  try {
    const { status } = yield call(api.delete, `/registrants/${payload}`);
    if (status === 200) {
      yield put(RegistrantActions.deleteRegistrantSuccess());
    }
  } catch (error) {
    yield put(
      RegistrantActions.deleteRegistrantError('Error deleting registrant')
    );
  }
}

function* getRegistrantsWatcher() {
  yield takeLatest(RegistrantTypes.GET_REGISTRANTS, getRegistrants);
}
function* getRegistrantByIdWatcher() {
  yield takeLatest(RegistrantTypes.GET_REGISTRANT_BY_ID, getRegistrantById);
}
function* postRegistrantWatcher() {
  yield takeLatest(RegistrantTypes.POST_REGISTRANT, postRegistrant);
}
function* putRegistrantWatcher() {
  yield takeLatest(RegistrantTypes.PUT_REGISTRANT, putRegistrant);
}
function* deleteRegistrantWatcher() {
  yield takeLatest(RegistrantTypes.DELETE_REGISTRANT, deleteRegistrant);
}

export default function* rootSaga() {
  yield all([
    fork(getRegistrantsWatcher),
    fork(postRegistrantWatcher),
    fork(getRegistrantByIdWatcher),
    fork(putRegistrantWatcher),
    fork(deleteRegistrantWatcher),
  ]);
}
