//ducks é o reducer da aplicação

export const Types = { //tipos 
    GET_GASRECS_SAVINGS: 'GET_GASRECS_SAVINGS',
    GET_GASRECS_SAVINGS_SUCCESS: 'GET_GASRECS_SAVINGS_SUCCESS',
    GET_GASRECS_SAVINGS_ERROR: 'GET_GASRECS_SAVINGS_ERROR',

    POST_GASRECS_SAVINGS: 'POST_GASRECS_SAVINGS',
    POST_GASRECS_SAVINGS_SUCCESS: 'POST_GASRECS_SAVINGS_SUCCESS',
    POST_GASRECS_SAVINGS_ERROR: 'POST_GASRECS_SAVINGS_ERROR',

    PUT_GASRECS_SAVINGS: 'PUT_GASRECS_SAVINGS',
    PUT_GASRECS_SAVINGS_SUCCESS: 'PUT_GASRECS_SAVINGS_SUCCESS',
    PUT_GASRECS_SAVINGS_ERROR: 'PUT_GASRECS_SAVINGS_ERROR',

    // GET_GASREC_SAVING_BY_ID: 'GET_GASREC_SAVING_BY_ID',
    // GET_GASREC_SAVING_BY_ID_SUCCESS: 'GET_GASREC_SAVING_BY_ID_SUCCESS',
    // GET_GASREC_SAVING_BY_ID_ERROR: 'GET_GASREC_SAVING_BY_ID_ERROR',

  };
  
  const INIT_STATE = { //estado inicial do reducer
    data: null,
    loading: false,
    error: null,
    postSuccess: false,
    amount: 10,
    // gasrec_saving: null,
  };
  
  
  export default function GasrecSaving(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_GASRECS_SAVINGS:
        return {
          ...state,
          data: null,
          loading: true,
          error: null,
        };
      case Types.GET_GASRECS_SAVINGS_SUCCESS:
        return {
          ...state,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
          loading: false,
          error: null,
        };
      case Types.GET_GASRECS_SAVINGS_ERROR:
        return {
          ...state,
          data: null,
          loading: false,
          error: action.payload,
        };

      case Types.POST_GASRECS_SAVINGS:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.POST_GASRECS_SAVINGS_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.POST_GASRECS_SAVINGS_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: action.payload?.message
        };

      case Types.PUT_GASRECS_SAVINGS:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.PUT_GASRECS_SAVINGS_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.PUT_GASRECS_SAVINGS_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: action.payload?.message
        };

      // case Types.GET_GASREC_SAVING_BY_ID:
      //   return {
      //     ...state,
      //     gasrec_saving: null,
      //     loading: true,
      //     error: false,
      //   };
      // case Types.GET_GASREC_SAVING_BY_ID_SUCCESS:
      //   return {
      //     ...state,
      //     gasrec_saving: action.payload,
      //     loading: false,
      //     error: false,
      //   };
      // case Types.GET_GASREC_SAVING_BY_ID_ERROR:
      //   return {
      //     ...state,
      //     gasrec_saving: null,
      //     loading: false,
      //     error: action.payload,
      //   };
      default:
        return state;
    }
  }
  
  export const Creators = { //actions da aplicação
    getGasrecSaving: payload => ({
      type: Types.GET_GASRECS_SAVINGS,
      payload,
    }),
    getGasrecSavingSuccess: payload => ({
      type: Types.GET_GASRECS_SAVINGS_SUCCESS,
      payload,
    }),
    getGasrecSavingError: payload => ({
      type: Types.GET_GASRECS_SAVINGS_ERROR,
      payload,
    }),

    postGasrecSaving: payload => ({
      type: Types.POST_GASRECS_SAVINGS,
      payload,
    }),
    postGasrecSavingSuccess: payload => ({
      type: Types.POST_GASRECS_SAVINGS_SUCCESS,
      payload,
    }),
    postGasrecSavingError: payload => ({
      type: Types.POST_GASRECS_SAVINGS_ERROR,
      payload,
    }),

    putGasrecSaving: payload => ({
      type: Types.PUT_GASRECS_SAVINGS,
      payload,
    }),
    putGasrecSavingSuccess: payload => ({
      type: Types.PUT_GASRECS_SAVINGS_SUCCESS,
      payload,
    }),
    putGasrecSavingError: payload => ({
      type: Types.PUT_GASRECS_SAVINGS_ERROR,
      payload,
    }),

    // getGasrecSavingById: payload => ({
    //   type: Types.GET_GASREC_SAVING_BY_ID,
    //   payload,
    // }),
    // getGasrecSavingByIdSuccess: payload => ({
    //   type: Types.GET_GASREC_SAVING_BY_ID_SUCCESS,
    //   payload,
    // }),
    // getGasrecSavingByIdError: payload => ({
    //   type: Types.GET_GASREC_SAVING_BY_ID_ERROR,
    //   payload,
    // }),

  };
  