import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './containers/App';
import indigoTheme from './containers/themes/indigoTheme';
import configureStore from './store';
import Declaration from './containers/Declaration';

export const store = configureStore();
const applyTheme = createMuiTheme(indigoTheme);

const MainApp = () => (
  <ThemeProvider theme={applyTheme}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/declaration/:hash" component={Declaration} />
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

export default MainApp;
