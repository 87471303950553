import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ParticipantActions,
  Types as ParticipantTypes,
} from 'store/ducks/Participant';
import api from '../../util/Api';

function* getList({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/participants', {
      params: payload,
    });
    if (status === 200) {
      yield put(ParticipantActions.getListParticipantSuccess(data));
    } else {
      yield put(ParticipantActions.getListParticipantFail());
    }
  } catch (err) {
    yield put(ParticipantActions.getListParticipantFail());
  }
}

function* deleteParticipant({ payload }) {
  try {
    const { status } = yield call(api.delete, `/participants/${payload}`);
    if (status === 200) {
      yield put(ParticipantActions.deleteParticipantSuccess());
    } else {
      yield put(ParticipantActions.deleteParticipantFail());
    }
  } catch (err) {
    yield put(ParticipantActions.deleteParticipantFail());
  }
}

function* getIdParticipant({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/participants/${payload}`);
    if (status === 200) {
      yield put(ParticipantActions.getIdParticipantSuccess(data));
    } else {
      yield put(ParticipantActions.getIdParticipantFail());
    }
  } catch (err) {
    yield put(ParticipantActions.getIdParticipantFail());
  }
}

function* createParticipant({ payload }) {
  try {
    const { status } = yield call(api.post, '/participants', payload);
    if (status === 200) {
      yield put(ParticipantActions.createParticipantSuccess());
    } else {
      yield put(ParticipantActions.createParticipantFail());
    }
  } catch (e) {
    yield put(ParticipantActions.createParticipantFail());
  }
}

function* updateParticipant({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `/participants/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(ParticipantActions.updateParticipantSuccess());
    } else {
      yield put(ParticipantActions.updateParticipantFail());
    }
  } catch (e) {
    yield put(ParticipantActions.updateParticipantFail());
  }
}

function* getListWatcher() {
  yield takeLatest(ParticipantTypes.GET_LIST_PARTICIPANT_REQUEST, getList);
}

function* deleteParticipantWatcher() {
  yield takeLatest(
    ParticipantTypes.DELETE_PARTICIPANT_REQUEST,
    deleteParticipant
  );
}

function* getIdParticipantWatcher() {
  yield takeLatest(
    ParticipantTypes.GET_ID_PARTICIPANT_REQUEST,
    getIdParticipant
  );
}

function* createParticipantWatcher() {
  yield takeLatest(
    ParticipantTypes.CREATE_PARTICIPANT_REQUEST,
    createParticipant
  );
}

function* updateParticipantWatcher() {
  yield takeLatest(
    ParticipantTypes.UPDATE_PARTICIPANT_REQUEST,
    updateParticipant
  );
}

export default function* rootSaga() {
  yield all([
    fork(getListWatcher),
    fork(deleteParticipantWatcher),
    fork(getIdParticipantWatcher),
    fork(createParticipantWatcher),
    fork(updateParticipantWatcher),
  ]);
}
