import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { getToken } from '../util';
import { Creators as AuthActions } from '../store/ducks/Auth';
import CustomSnackbars from '../components/CustomSnackbars';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('Type your email'),
  password: yup.string().required('Type your password'),
});

const SignIn = props => {
  const dispatch = useDispatch();
  const token = getToken();
  const { authUserError: error, authUserLoading: loading } = useSelector(
    ({ auth }) => auth
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('error');
  const [titleMessage, setTitleMessage] = useState('error');

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    dispatch(AuthActions.login(data));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      setTitleMessage('Error');
      setTypeMessage('error');
      if (error.message === 'E_PASSWORD_MISMATCH') {
        setMessage('incorrect password');
      }
      if (error.message === 'E_USER_NOT_FOUND') {
        setMessage('User not found');
      }
      setOpen(true);
    }
  }, [error]);

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              src={require('assets/images/fullLogo.svg')}
              alt="SISGASREC"
              title="SISGASREC"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <Typography variant="h5">
              <IntlMessages id="appModule.nameSystem" />
            </Typography>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  defaultValue=""
                  margin="normal"
                  className="mt-1 my-sm-3"
                  name="email"
                  inputRef={register}
                />
                <p style={{ color: 'red' }}>{errors.email?.message}</p>
                <TextField
                  type="password"
                  name="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  defaultValue=""
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={register}
                />
                <p style={{ color: 'red' }}>{errors.password?.message}</p>
                <div className="d-flex justify-content-end">
                  <a href="/recoverEmail">Recover Password</a>
                </div>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  {loading ? (
                    <CircularProgress size={20} className="ml-4" />
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  )}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <CustomSnackbars
        open={open}
        setOpen={setOpen}
        text={message}
        type={typeMessage}
        // title={titleMessage}
      />

      {/* {message && NotificationManager.error(message)}
      <NotificationContainer /> */}
    </div>
  );
};

export default SignIn;
