export const Types = {
    GET_TRANSATION_RETIRED: 'GET_TRANSATION_RETIRED',
    GET_TRANSATION_RETIRED_SUCCESS: 'GET_TRANSATION_RETIRED_SUCCESS',
    GET_TRANSATION_RETIRED_ERROR: 'GET_TRANSATION_RETIRED_ERROR',

    GET_GASREC_RETIRED: 'GET_GASREC_RETIRED',
    GET_GASREC_RETIRED_SUCCESS: 'GET_GASREC_RETIRED_SUCCESS',
    GET_GASREC_RETIRED_ERROR: 'GET_GASREC_RETIRED_ERROR',
  
    GET_PDF_TRANSATION: 'GET_PDF_TRANSATION',
    GET_PDF_TRANSATION_ERROR: 'GET_PDF_TRANSATION_ERROR',
    GET_PDF_TRANSATION_SUCCESS: 'GET_PDF_TRANSATION_SUCCESS',
    GET_PDF_TRANSATION_RESET: 'GET_PDF_TRANSATION_RESET',
  
  };
  
  const INIT_STATE = {
    data: null,
    loading: false,
    error: false,
    amount: 10,
    getPDFSucces:false,
    getPDFLoading: false,
    getPDFError: false,
  };
  
  export default function TransationRetired(state = INIT_STATE, action) {

    switch (action.type) {
      case Types.GET_TRANSATION_RETIRED:
        return {
          ...state,
          data: null,
          loading: true,
          error: false,
        };
      case Types.GET_TRANSATION_RETIRED_SUCCESS:
        return {
          ...state,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
          loading: false,
          error: false,
        };
      case Types.GET_TRANSATION_RETIRED_ERROR:
        return {
          ...state,
          data: null,
          loading: false,
          error: true,
        };
      case Types.GET_GASREC_RETIRED:
        return {
          ...state,
          data: null,
          loading: true,
          error: false,
        };
      case Types.GET_GASREC_RETIRED_SUCCESS:
        return {
          ...state,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
          loading: false,
          error: false,
        };
      case Types.GET_GASREC_RETIRED_ERROR:
        return {
          ...state,
          data: null,
          loading: false,
          error: true,
        };
      case Types.GET_PDF_TRANSATION:
        return {
          ...state,
          getPDFLoading: true,
          getPDFError: false,
        };
      case Types.GET_PDF_TRANSATION_SUCCESS:
        return {
          ...state,
          getPDFSucces: true,
          getPDFLoading: false,
          getPDFError: false,
        };
      case Types.GET_PDF_TRANSATION_ERROR:
        return {
          ...state,
          getPDFLoading: false,
          getPDFError: true,
        };
      case Types.GET_PDF_TRANSATION_RESET:
        return {
          ...state,
          getPDFLoading: false,
          getPDFError: false,
        };
      default:
        return state;
    }
  }
  
  export const Creators = {
    getTransationRetired: payload => ({
      type: Types.GET_TRANSATION_RETIRED,
      payload,
    }),
    getTransationRetiredSuccess: payload => ({
      type: Types.GET_TRANSATION_RETIRED_SUCCESS,
      payload,
    }),
    getTransationRetiredError: () => ({
      type: Types.GET_TRANSATION_RETIRED_ERROR,
    }),
    getGasrecRetired: payload => ({
      type: Types.GET_GASREC_RETIRED,
      payload,
    }),
    getGasrecRetiredSuccess: payload => ({
      type: Types.GET_GASREC_RETIRED_SUCCESS,
      payload,
    }),
    getGasrecRetiredError: () => ({
      type: Types.GET_GASREC_RETIRED_ERROR,
    }),
    getPdfTransation: payload => ({
      type: Types.GET_PDF_TRANSATION,
      payload,
    }),
    getPdfTransationSuccess: () => ({
      type: Types.GET_PDF_TRANSATION_SUCCESS,
    }),
    getPdfTrnsationError: () => ({
      type: Types.GET_PDF_TRANSATION_ERROR,
    }),
    getPdfTransationReset: () => ({
      type: Types.GET_PDF_TRANSATION_RESET,
    }),
  };
  