import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { Creators as SettingActions } from 'store/ducks/Setting';
import SideBarContent from './SideBarContent';

const SideBar = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const { drawerType, navigationStyle, navCollapsed } = useSelector(
    ({ settings }) => settings
  );
  const storageTypeUser = sessionStorage.getItem('TOTUM.TYPE_USER');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width < 1080 && drawerType !== 'fixed_drawer') {
      const nav = true;
      const draw = 'fixed_drawer';
      dispatch(SettingActions.toggleCollapsedNav({ nav, draw }));
    }
  }, [width]);

  const onToggleCollapsedNav = e => {
    dispatch(
      SettingActions.toggleCollapsedNav({ nav: false, draw: 'fixed_drawer' })
    );
  };

  let drawerStyle = drawerType?.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType?.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType?.includes(COLLAPSED_DRAWER) ||
    (drawerType?.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
      >
        <UserInfo />
        <SideBarContent typeUser={storageTypeUser} />
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
