export const Types = {
  GET_PRODUCTION_DETAILS: 'GET_PRODUCTION_DETAILS',
  GET_PRODUCTION_DETAILS_SUCCESS: 'GET_PRODUCTION_DETAILS_SUCCESS',
  GET_PRODUCTION_DETAILS_ERROR: 'GET_PRODUCTION_DETAILS_ERROR',

  POST_PRODUCTION_DETAIL: 'POST_PRODUCTION_DETAIL',
  POST_PRODUCTION_DETAIL_SUCCESS: 'POST_PRODUCTION_DETAIL_SUCCESS',
  POST_PRODUCTION_DETAIL_ERROR: 'POST_PRODUCTION_DETAIL_ERROR',

  GET_PRODUCTION_DETAIL_BY_ID: 'GET_PRODUCTION_DETAIL_BY_ID',
  GET_PRODUCTION_DETAIL_BY_ID_SUCCESS: 'GET_PRODUCTION_DETAIL_BY_ID_SUCCESS',
  GET_PRODUCTION_DETAIL_BY_ID_ERROR: 'GET_PRODUCTION_DETAIL_BY_ID_ERROR',

  PUT_PRODUCTION_DETAIL: 'PUT_PRODUCTION_DETAIL',
  PUT_PRODUCTION_DETAIL_SUCCESS: 'PUT_PRODUCTION_DETAIL_SUCCESS',
  PUT_PRODUCTION_DETAIL_ERROR: 'PUT_PRODUCTION_DETAIL_ERROR',

  DELETE_PRODUCTION_DETAIL: 'DELETE_PRODUCTION_DETAIL',
  DELETE_PRODUCTION_DETAIL_SUCCESS: 'DELETE_PRODUCTION_DETAIL_SUCCESS',
  DELETE_PRODUCTION_DETAIL_ERROR: 'DELETE_PRODUCTION_DETAIL_ERROR',

  APPROVE_PRODUCTION_DETAIL: 'APPROVE_PRODUCTION_DETAIL',
  APPROVE_PRODUCTION_DETAIL_SUCCESS: 'APPROVE_PRODUCTION_DETAIL_SUCCESS',
  APPROVE_PRODUCTION_DETAIL_ERROR: 'APPROVE_PRODUCTION_DETAIL_ERROR',
  APPROVE_PRODUCTION_DETAIL_RESET: 'APPROVE_PRODUCTION_DETAIL_RESET',

  REFUSE_PRODUCTION_DETAIL: 'REFUSE_PRODUCTION_DETAIL',
  REFUSE_PRODUCTION_DETAIL_SUCCESS: 'REFUSE_PRODUCTION_DETAIL_SUCCESS',
  REFUSE_PRODUCTION_DETAIL_ERROR: 'REFUSE_PRODUCTION_DETAIL_ERROR',
  REFUSE_PRODUCTION_DETAIL_RESET: 'REFUSE_PRODUCTION_DETAIL_RESET',

  PRODUCTION_DETAIL_CLEAN: 'PRODUCTION_DETAIL_CLEAN',


  GET_REQUEST_AUDIT: 'GET_REQUEST_AUDIT',
  GET_REQUEST_AUDIT_SUCCESS: 'GET_REQUEST_AUDIT_SUCCESS',
  GET_REQUEST_AUDIT_ERROR: 'GET_REQUEST_AUDIT_ERROR',
  GET_REQUEST_AUDIT_RESET: 'GET_REQUEST_AUDIT_RESET',
};

const INIT_STATE = {
  data: null,
  production_detail: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  requestAuditSucces: false,
  requestAuditLoading: false,
  requestAuditError: false,

  amount: 10,
};

export default function ProductionDetail(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_PRODUCTION_DETAILS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_PRODUCTION_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_PRODUCTION_DETAILS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_PRODUCTION_DETAIL:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_PRODUCTION_DETAIL_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload?.message,
      };
    case Types.GET_PRODUCTION_DETAIL_BY_ID:
      return {
        ...state,
        production_detail: null,
        loading: true,
        error: false,
      };
    case Types.GET_PRODUCTION_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        production_detail: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_PRODUCTION_DETAIL_BY_ID_ERROR:
      return {
        ...state,
        production_detail: null,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_PRODUCTION_DETAIL:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_PRODUCTION_DETAIL_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.DELETE_PRODUCTION_DETAIL:
      return {
        ...state,
        deleteSuccess: false,
        loading: true,
        error: null,
      };
    case Types.DELETE_PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
        error: null,
      };
    case Types.DELETE_PRODUCTION_DETAIL_ERROR:
      return {
        ...state,
        deleteSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.PRODUCTION_DETAIL_CLEAN:
      return {
        ...state,
        production_detail: null,
        postSuccess: false,
        deleteSuccess: false,
        loading: false,
        error: false,
      };

    case Types.APPROVE_PRODUCTION_DETAIL:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_PRODUCTION_DETAIL_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: action.payload,
      };
    case Types.APPROVE_PRODUCTION_DETAIL_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.REFUSE_PRODUCTION_DETAIL:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_PRODUCTION_DETAIL_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_PRODUCTION_DETAIL_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_PRODUCTION_DETAIL_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };

    case Types.GET_REQUEST_AUDIT:
      return {
        ...state,
        requestAuditLoading: true,
        requestAuditError: false,
      };
    case Types.GET_REQUEST_AUDIT_SUCCESS:
      return {
        ...state,
        requestAuditSucces: true,
        requestAuditLoading: false,
        requestAuditError: false,
      };
    case Types.GET_REQUEST_AUDIT_ERROR:
      return {
        ...state,
        requestAuditLoading: false,
        requestAuditError: true,
      };
    case Types.GET_REQUEST_AUDIT_RESET:
      return {
        ...state,
        requestAuditLoading: false,
        requestAuditSucces: false,
        requestAuditError: false,
      };

    default:
      return state;
  }
}





export const Creators = {
  getProductionDetails: payload => ({
    type: Types.GET_PRODUCTION_DETAILS,
    payload,
  }),
  getProductionDetailsSuccess: payload => ({
    type: Types.GET_PRODUCTION_DETAILS_SUCCESS,
    payload,
  }),
  getProductionDetailsError: payload => ({
    type: Types.GET_PRODUCTION_DETAILS_ERROR,
    payload,
  }),
  postProductionDetail: payload => ({
    type: Types.POST_PRODUCTION_DETAIL,
    payload,
  }),
  postProductionDetailSuccess: payload => ({
    type: Types.POST_PRODUCTION_DETAIL_SUCCESS,
    payload,
  }),
  postProductionDetailError: payload => ({
    type: Types.POST_PRODUCTION_DETAIL_ERROR,
    payload,
  }),
  getProductionDetailById: payload => ({
    type: Types.GET_PRODUCTION_DETAIL_BY_ID,
    payload,
  }),
  getProductionDetailByIdSuccess: payload => ({
    type: Types.GET_PRODUCTION_DETAIL_BY_ID_SUCCESS,
    payload,
  }),
  getProductionDetailByIdError: payload => ({
    type: Types.GET_PRODUCTION_DETAIL_BY_ID_ERROR,
    payload,
  }),
  putProductionDetail: payload => ({
    type: Types.PUT_PRODUCTION_DETAIL,
    payload,
  }),
  putProductionDetailSuccess: payload => ({
    type: Types.PUT_PRODUCTION_DETAIL_SUCCESS,
    payload,
  }),
  putProductionDetailError: payload => ({
    type: Types.PUT_PRODUCTION_DETAIL_ERROR,
    payload,
  }),
  deleteProductionDetail: payload => ({
    type: Types.DELETE_PRODUCTION_DETAIL,
    payload,
  }),
  deleteProductionDetailSuccess: payload => ({
    type: Types.DELETE_PRODUCTION_DETAIL_SUCCESS,
    payload,
  }),
  deleteProductionDetailError: payload => ({
    type: Types.DELETE_PRODUCTION_DETAIL_ERROR,
    payload,
  }),
  ProductionDetailClean: () => ({
    type: Types.PRODUCTION_DETAIL_CLEAN,
  }),
  approveProductionDetail: payload => ({
    type: Types.APPROVE_PRODUCTION_DETAIL,
    payload,
  }),
  approveProductionDetailSuccess: () => ({
    type: Types.APPROVE_PRODUCTION_DETAIL_SUCCESS,
  }),
  approveProductionDetailError: payload => ({
    type: Types.APPROVE_PRODUCTION_DETAIL_ERROR,
    payload,
  }),
  approveProductionDetailReset: () => ({
    type: Types.APPROVE_PRODUCTION_DETAIL_RESET,
  }),
  refuseProductionDetail: payload => ({
    type: Types.REFUSE_PRODUCTION_DETAIL,
    payload,
  }),
  refuseProductionDetailSuccess: () => ({
    type: Types.REFUSE_PRODUCTION_DETAIL_SUCCESS,
  }),
  refuseProductionDetailError: () => ({
    type: Types.REFUSE_PRODUCTION_DETAIL_ERROR,
  }),
  refuseProductionDetailReset: () => ({
    type: Types.REFUSE_PRODUCTION_DETAIL_RESET,
  }),

  getRequestAudit: payload => ({
    type: Types.GET_REQUEST_AUDIT,
    payload,
  }),
  getRequestAuditSuccess: () => ({
    type: Types.GET_REQUEST_AUDIT_SUCCESS,
  }),
  getRequestAuditError: () => ({
    type: Types.GET_REQUEST_AUDIT_ERROR,
  }),
  getRequestAuditReset: () => ({
    type: Types.GET_REQUEST_AUDIT_RESET,
  }),


};
