import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as IssuerDashboardTypes,
  Creators as IssuerDashboardActions,
} from '../ducks/IssuerDashboard';
import api from '../../util/Api';

function* getIssuerTopNumbers() {
  try {
    const { status, data } = yield call(api.get, '/issuerDashboard/topNumbers');
    if (status === 200) {
      yield put(
        IssuerDashboardActions.getIssuerTopNumbersSuccess(data)
      );
    } else {
      yield put(
        IssuerDashboardActions.getIssuerTopNumbersError(
          'Error listing topnumbers'
        )
      );
    }
  } catch {
    yield put(
      IssuerDashboardActions.getIssuerTopNumbersError(
        'Error listing topnumbers'
      )
    );
  }
}

function* getIssuerTopNumbersWatcher() {
  yield takeLatest(
    IssuerDashboardTypes.GET_ISSUER_TOP_NUMBERS,
    getIssuerTopNumbers
  );
}


export default function* rootSaga() {
  yield all([
    fork(getIssuerTopNumbersWatcher),
  ]);
}
