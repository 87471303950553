export const Types = {
    GET_LIST_ISSUER_REQUEST: 'GET_LIST_ISSUER_REQUEST',
    GET_LIST_ISSUER_SUCCESS: 'GET_LIST_ISSUER_SUCCESS',
    GET_LIST_ISSUER_FAIL: 'GET_LIST_ISSUER_FAIL',
  
    DELETE_ISSUER_REQUEST: 'DELETE_ISSUER_REQUEST',
    DELETE_ISSUER_SUCCESS: 'DELETE_ISSUER_SUCCESS',
    DELETE_ISSUER_FAIL: 'DELETE_ISSUER_FAIL',
    DELETE_ISSUER_CLEAN: 'DELETE_ISSUER_CLEAN',
  
    GET_ID_ISSUER_REQUEST: 'GET_ID_ISSUER_REQUEST',
    GET_ID_ISSUER_SUCCESS: 'GET_ID_ISSUER_SUCCESS',
    GET_ID_ISSUER_FAIL: 'GET_ID_ISSUER_FAIL',
  
    CREATE_ISSUER_REQUEST: 'CREATE_ISSUER_REQUEST',
    CREATE_ISSUER_SUCCESS: 'CREATE_ISSUER_SUCCESS',
    CREATE_ISSUER_FAIL: 'CREATE_ISSUER_FAIL',
    CREATE_ISSUER_CLEAN: 'CREATE_ISSUER_CLEAN',
  
    UPDATE_ISSUER_REQUEST: 'UPDATE_ISSUER_REQUEST',
    UPDATE_ISSUER_SUCCESS: 'UPDATE_ISSUER_SUCCESS',
    UPDATE_ISSUER_FAIL: 'UPDATE_ISSUER_FAIL',
    UPDATE_ISSUER_CLEAN: 'UPDATE_ISSUER_CLEAN',
  };
  
  const INIT_STATE = {
    amount: 10,
  
    data: null,
    loading: false,
    error: false,
  
    deleteLoading: false,
    deleteSuccess: false,
    deleteError: false,
  
    idLoading: false,
    idData: null,
    idError: false,
  
    createLoading: false,
    createSuccess: false,
    createError: false,
  
    updateLoading: false,
    updateSuccess: false,
    updateError: false,
  };
  
  export default function Issuer(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_LIST_ISSUER_REQUEST:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case Types.GET_LIST_ISSUER_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
        };
      case Types.GET_LIST_ISSUER_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
  
      case Types.DELETE_ISSUER_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: false,
          deleteSuccess: false,
        };
      case Types.DELETE_ISSUER_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: false,
          deleteSuccess: true,
        };
      case Types.DELETE_ISSUER_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: true,
          deleteSuccess: false,
        };
      case Types.DELETE_ISSUER_CLEAN:
        return {
          ...state,
          deleteLoading: false,
          deleteError: false,
          deleteSuccess: false,
        };
  
      case Types.GET_ID_ISSUER_REQUEST:
        return {
          ...state,
          idLoading: true,
          idError: false,
        };
      case Types.GET_ID_ISSUER_SUCCESS:
        return {
          ...state,
          idLoading: false,
          idError: false,
          idData: action.payload,
        };
      case Types.GET_ID_ISSUER_FAIL:
        return {
          ...state,
          idLoading: false,
          idError: true,
        };
  
      case Types.CREATE_ISSUER_REQUEST:
        return {
          ...state,
          createLoading: true,
          createError: false,
          createSuccess: false,
        };
      case Types.CREATE_ISSUER_SUCCESS:
        return {
          ...state,
          createLoading: false,
          createError: false,
          createSuccess: true,
        };
      case Types.CREATE_ISSUER_FAIL:
        return {
          ...state,
          createLoading: false,
          createError: true,
          createSuccess: false,
        };
      case Types.CREATE_ISSUER_CLEAN:
        return {
          ...state,
          createLoading: false,
          createError: false,
          createSuccess: false,
        };
  
      case Types.UPDATE_ISSUER_REQUEST:
        return {
          ...state,
          updateLoading: true,
          updateError: false,
          updateSuccess: false,
        };
      case Types.UPDATE_ISSUER_SUCCESS:
        return {
          ...state,
          updateLoading: false,
          updateError: false,
          updateSuccess: true,
        };
      case Types.UPDATE_ISSUER_FAIL:
        return {
          ...state,
          updateLoading: false,
          updateError: true,
          updateSuccess: false,
        };
      case Types.UPDATE_ISSUER_CLEAN:
        return {
          ...state,
          updateLoading: false,
          updateError: false,
          updateSuccess: false,
        };
  
      default:
        return state;
    }
  }
  
  export const Creators = {
    // GET
    getListIssuerRequest: payload => ({
      type: Types.GET_LIST_ISSUER_REQUEST,
      payload,
    }),
    getListIssuerSuccess: payload => ({
      type: Types.GET_LIST_ISSUER_SUCCESS,
      payload,
    }),
    getListIssuerFail: () => ({
      type: Types.GET_LIST_ISSUER_FAIL,
    }),
  
    // DELETE
    deleteIssuerRequest: payload => ({
      type: Types.DELETE_ISSUER_REQUEST,
      payload,
    }),
    deleteIssuerSuccess: () => ({
      type: Types.DELETE_ISSUER_SUCCESS,
    }),
    deleteIssuerFail: () => ({
      type: Types.DELETE_ISSUER_FAIL,
    }),
    deleteIssuerClean: () => ({
      type: Types.DELETE_ISSUER_CLEAN,
    }),
  
    // ID
    getIdIssuerRequest: payload => ({
      type: Types.GET_ID_ISSUER_REQUEST,
      payload,
    }),
    getIdIssuerSuccess: payload => ({
      type: Types.GET_ID_ISSUER_SUCCESS,
      payload,
    }),
    getIdIssuerFail: () => ({
      type: Types.GET_ID_ISSUER_FAIL,
    }),
  
    // CREATE
    createIssuerRequest: payload => ({
      type: Types.CREATE_ISSUER_REQUEST,
      payload,
    }),
    createIssuerSuccess: () => ({
      type: Types.CREATE_ISSUER_SUCCESS,
    }),
    createIssuerFail: () => ({
      type: Types.CREATE_ISSUER_FAIL,
    }),
    createIssuerClean: () => ({
      type: Types.CREATE_ISSUER_CLEAN,
    }),
  
    // UPDATE
    updateIssuerRequest: payload => ({
      type: Types.UPDATE_ISSUER_REQUEST,
      payload,
    }),
    updateIssuerSuccess: payload => ({
      type: Types.UPDATE_ISSUER_SUCCESS,
      payload,
    }),
    updateIssuerFail: () => ({
      type: Types.UPDATE_ISSUER_FAIL,
    }),
    updateIssuerClean: () => ({
      type: Types.UPDATE_ISSUER_CLEAN,
    }),
  };
  