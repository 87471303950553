import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { Types as UserTypes, Creators as UserActions } from '../ducks/User';
import api from '../../util/Api';

function* getUsers({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/clients', {
      params: payload,
    });
    if (status === 200) {
      yield put(UserActions.getUsersSuccess(data));
    }
  } catch (error) {
    yield put(UserActions.getUsersError('Error listing users'));
  }
}

function* postUser({ payload }) {
  try {
    const { status } = yield call(api.post, '/clients', payload);
    if (status === 200) {
      yield put(UserActions.postUserSuccess());
    }
  } catch (error) {
    yield put(UserActions.postUserError('Error registering user'));
  }
}

function* getUserById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/clients/${payload}`);
    if (status === 200) {
      yield put(UserActions.getUserByIdSuccess(data));
    }
  } catch (error) {
    yield put(UserActions.getUserByIdError('Error listing user'));
  }
}

function* putUser({ payload }) {
  const { userId } = payload;
  try {
    const { status } = yield call(api.put, `/clients/${userId}`, payload.data);
    if (status === 200) {
      yield put(UserActions.putUserSuccess());
    }
  } catch (error) {
    yield put(UserActions.putUserError('Error editing user'));
  }
}

function* getUsersWatcher() {
  yield takeLatest(UserTypes.GET_USERS, getUsers);
}
function* postUserWatcher() {
  yield takeLatest(UserTypes.POST_USER, postUser);
}
function* getUserByIdWatcher() {
  yield takeLatest(UserTypes.GET_USER_BY_ID, getUserById);
}
function* putUserWatcher() {
  yield takeLatest(UserTypes.PUT_USER, putUser);
}
export default function* rootSaga() {
  yield all([
    fork(getUsersWatcher),
    fork(postUserWatcher),
    fork(getUserByIdWatcher),
    fork(putUserWatcher),
  ]);
}
