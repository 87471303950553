//ducks é o reducer da aplicação

export const Types = { //tipos 
    GET_GASRECS_PARTICIPANTS: 'GET_GASRECS_PARTICIPANTS',
    GET_GASRECS_PARTICIPANTS_SUCCESS: 'GET_GASRECS_PARTICIPANTS_SUCCESS',
    GET_GASRECS_PARTICIPANTS_ERROR: 'GET_GASRECS_PARTICIPANTS_ERROR',
    POST_GASRECS_PARTICIPANTS: 'POST_GASRECS_PARTICIPANTS',
    POST_GASRECS_PARTICIPANTS_SUCCESS: 'POST_GASRECS_PARTICIPANTS_SUCCESS',
    POST_GASRECS_PARTICIPANTS_ERROR: 'POST_GASRECS_PARTICIPANTS_ERROR',
    GET_GASREC_PARTICIPANT_BY_ID: 'GET_GASREC_PARTICIPANT_BY_ID',
    GET_GASREC_PARTICIPANT_BY_ID_SUCCESS: 'GET_GASREC_PARTICIPANT_BY_ID_SUCCESS',
    GET_GASREC_PARTICIPANT_BY_ID_ERROR: 'GET_GASREC_PARTICIPANT_BY_ID_ERROR',
    REFUSE_GASREC: 'REFUSE_GASREC',
    REFUSE_GASREC_SUCCESS: 'REFUSE_GASREC_SUCCESS',
    REFUSE_GASREC_ERROR: 'REFUSE_GASREC_ERROR',
    REFUSE_GASREC_RESET: 'REFUSE_GASREC_RESET',
    GASREC_CLEAN: 'GASREC_CLEAN',
  };
  
  const INIT_STATE = { //estado inicial do reducer
    data: null,
    gasrec: null,
    loading: false,
    error: null,
    postSuccess: false,
    deleteSuccess: false,
  
    approveLoading: false,
    approveSuccess: false,
    approveError: false,
  
    refuseLoading: false,
    refuseSuccess: false,
    refuseError: false,
  
    amount: 10,
  };
  
  const GASREC_ERROR = {
    CEG_NOT_FOUND:
      'CEG not registered on the platform. Contact system administrator',
    CEG_HAS_ALREADY_BEEN_USED: 'CEG already registered in another unit.',
  };
  
  export default function Gasrec(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_GASRECS_PARTICIPANTS:
        return {
          ...state,
          data: null,
          loading: true,
          error: null,
        };
      case Types.GET_GASRECS_PARTICIPANTS_SUCCESS:
        return {
          ...state,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
          loading: false,
          error: null,
        };
      case Types.GET_GASRECS_PARTICIPANTS_ERROR:
        return {
          ...state,
          data: null,
          loading: false,
          error: action.payload,
        };
      case Types.POST_GASRECS_PARTICIPANTS:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.POST_GASRECS_PARTICIPANTS_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.POST_GASRECS_PARTICIPANTS_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: GASREC_ERROR?.[action.payload?.message] || action.payload?.message,
        };
      case Types.GET_GASREC_PARTICIPANT_BY_ID:
        return {
          ...state,
          gasrec: null,
          loading: true,
          error: false,
        };
      case Types.GET_GASREC_PARTICIPANT_BY_ID_SUCCESS:
        return {
          ...state,
          gasrec: action.payload,
          loading: false,
          error: false,
        };
      case Types.GET_GASREC_PARTICIPANT_BY_ID_ERROR:
        return {
          ...state,
          gasrec: null,
          loading: false,
          error: action.payload,
        };
      case Types.PUT_GASREC:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.PUT_GASREC_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.PUT_GASREC_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: action.payload,
        };
      case Types.DELETE_GASREC:
        return {
          ...state,
          deleteSuccess: false,
          loading: true,
          error: null,
        };
      case Types.DELETE_GASREC_SUCCESS:
        return {
          ...state,
          deleteSuccess: true,
          loading: false,
          error: null,
        };
      case Types.DELETE_GASREC_ERROR:
        return {
          ...state,
          deleteSuccess: false,
          loading: false,
          error: action.payload,
        };
      case Types.GASREC_CLEAN:
        return {
          ...state,
          gasrec: null,
          postSuccess: false,
          deleteSuccess: false,
          loading: false,
          error: action.payload,
        };
      case Types.REFUSE_GASREC:
        return {
          ...state,
          refuseLoading: true,
          refuseSuccess: false,
          refuseError: false,
        };
      case Types.REFUSE_GASREC_SUCCESS:
        return {
          ...state,
          refuseLoading: false,
          refuseSuccess: true,
          refuseError: false,
        };
      case Types.REFUSE_GASREC_ERROR:
        return {
          ...state,
          refuseLoading: false,
          refuseSuccess: false,
          refuseError: true,
        };
      case Types.REFUSE_GASREC_RESET:
        return {
          ...state,
          refuseLoading: false,
          refuseSuccess: false,
          refuseError: false,
        };
      default:
        return state;
    }
  }
  
  export const Creators = { //actions da aplicação
    getGasrecs: payload => ({
      type: Types.GET_GASRECS_PARTICIPANTS,
      payload,
    }),
    getGasrecsSuccess: payload => ({
      type: Types.GET_GASRECS_PARTICIPANTS_SUCCESS,
      payload,
    }),
    getGasrecsError: payload => ({
      type: Types.GET_GASRECS_PARTICIPANTS_ERROR,
      payload,
    }),
    postGasrec: payload => ({
      type: Types.POST_GASRECS_PARTICIPANTS,
      payload,
    }),
    postGasrecSuccess: payload => ({
      type: Types.POST_GASRECS_PARTICIPANTS_SUCCESS,
      payload,
    }),
    postGasrecError: payload => ({
      type: Types.POST_GASRECS_PARTICIPANTS_ERROR,
      payload,
    }),
    getGasrecById: payload => ({
      type: Types.GET_GASREC_PARTICIPANT_BY_ID,
      payload,
    }),
    getGasrecByIdSuccess: payload => ({
      type: Types.GET_GASREC_PARTICIPANT_BY_ID_SUCCESS,
      payload,
    }),
    getGasrecByIdError: payload => ({
      type: Types.GET_GASREC_PARTICIPANT_BY_ID_ERROR,
      payload,
    }),
    putGasrec: payload => ({
      type: Types.PUT_GASREC,
      payload,
    }),
    putGasrecSuccess: payload => ({
      type: Types.PUT_GASREC_SUCCESS,
      payload,
    }),
    putGasrecError: payload => ({
      type: Types.PUT_GASREC_ERROR,
      payload,
    }),
    deleteGasrec: payload => ({
      type: Types.DELETE_GASREC,
      payload,
    }),
    deleteGasrecSuccess: payload => ({
      type: Types.DELETE_GASREC_SUCCESS,
      payload,
    }),
    deleteGasrecError: payload => ({
      type: Types.DELETE_GASREC_ERROR,
      payload,
    }),
    GasrecClean: () => ({
      type: Types.GASREC_CLEAN,
    }),
    approveGasrec: payload => ({
      type: Types.APPROVE_GASREC,
      payload,
    }),
    approveGasrecSuccess: () => ({
      type: Types.APPROVE_GASREC_SUCCESS,
    }),
    approveGasrecError: payload => ({
      type: Types.APPROVE_GASREC_ERROR,
      payload,
    }),
    approveGasrecReset: () => ({
      type: Types.APPROVE_GASREC_RESET,
    }),
    refuseGasrec: payload => ({
      type: Types.REFUSE_GASREC,
      payload,
    }),
    refuseGasrecSuccess: () => ({
      type: Types.REFUSE_GASREC_SUCCESS,
    }),
    refuseGasrecError: () => ({
      type: Types.REFUSE_GASREC_ERROR,
    }),
    refuseGasrecReset: () => ({
      type: Types.REFUSE_GASREC_RESET,
    }),
  };
  