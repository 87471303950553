import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import { Creators as RecActions, Types as RecTypes } from 'store/ducks/Rec';
import axios from 'axios';
import api from '../../util/Api';

function* getRecs({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/recs', {
      params: payload,
    });
    if (status === 200) {
      yield put(RecActions.getRecsSuccess(data));
    } else {
      yield put(RecActions.getRecsError());
    }
  } catch (err) {
    yield put(RecActions.getRecsError());
  }
}

function* getRecsImports({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/imports/generation', {
      params: payload,
      timeout: 6000000,
    });
    if (status === 200) {
      yield put(RecActions.getRecsImportSuccess(data));
    } else {
      yield put(RecActions.getRecsImportError());
    }
  } catch (err) {
    yield put(RecActions.getRecsImportError());
  }
}

function* commitImport({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/imports/commit', payload);
    if (status === 200) {
      yield put(RecActions.commitImportSuccess(data));
    } else {
      yield put(RecActions.commitImportError(data));
    }
  } catch (err) {
    yield put(
      RecActions.commitImportError(err?.response?.data || err?.data || err)
    );
  }
}

function* getRecsBusiness({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/imports/business', {
      params: payload,
    });
    if (status === 200) {
      yield put(RecActions.getRecsBusinessSuccess(data));
    } else {
      yield put(RecActions.getRecsBusinessError());
    }
  } catch (err) {
    yield put(RecActions.getRecsBusinessError());
  }
}

function* postRecs({ payload }) {
  try {
    const { status } = yield call(api.post, '/recs', payload);
    if (status === 200) {
      yield put(RecActions.postRecsSuccess());
    } else {
      yield put(RecActions.postRecsError());
    }
  } catch (err) {
    if (err.response.status === 429) {
      yield put(
        RecActions.postRecsError({ error: 'You have reached the emission limit' })
      );
    } else {
      yield put(RecActions.postRecsError(err.response));
    }
  }
}

function* generationRecs({ payload }) {
  try {
    const { status, data } = yield call(
      api.post,
      '/imports/pre-generation',
      payload
    );
    if (status === 200) {
      yield put(RecActions.generationRecsSuccess());
    } else {
      yield put(RecActions.generationRecsError(data));
    }
  } catch (err) {
    yield put(RecActions.generationRecsError(err?.response?.data));
  }
}

function* putRecs({ payload }) {
  const { form, id } = payload;
  try {
    const { status } = yield call(api.put, `/recs/${id}`, form);
    if (status === 200) {
      yield put(RecActions.putRecsSuccess());
    } else {
      yield put(RecActions.putRecsError());
    }
  } catch (err) {
    yield put(RecActions.putRecsError());
  }
}

function* approveRec({ payload }) {
  try {
    const { status } = yield call(api.post, `/recs/approve/${payload}`);
    if (status === 200) {
      yield put(RecActions.approveRecSuccess());
    } else {
      yield put(RecActions.approveRecError());
    }
  } catch (err) {
    yield put(RecActions.approveRecError(err.response.data.message));
  }
}

function* blockRec({ payload }) {
  try {
    const { id, ...body } = payload;
    const { status } = yield call(api.post, `/recs/block/${id}`, body);
    if (status === 200) {
      yield put(RecActions.blockRecSuccess());
    } else {
      yield put(RecActions.blockRecError());
    }
  } catch (err) {
    yield put(RecActions.blockRecError());
  }
}

function* refuseRec({ payload }) {
  try {
    const { status } = yield call(api.post, `/recs/refuse/${payload}`);
    if (status === 200) {
      yield put(RecActions.refuseRecSuccess());
    } else {
      yield put(RecActions.refuseRecError());
    }
  } catch (err) {
    yield put(RecActions.refuseRecError());
  }
}

function* getPDF({ payload }) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_API_URL}/pdf/getPdf/${payload}`,
      {
        responseType: 'blob',
      }
    );
    if (response.data) {
      const file = new Blob([response?.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    if (response.status === 200) {
      yield put(RecActions.getPdfSuccess());
    } else {
      yield put(RecActions.getPdfError());
    }
  } catch (err) {
    console.log('');
  }
}

function* getRecsWatcher() {
  yield takeLatest(RecTypes.GET_RECS, getRecs);
}

function* getRecsImportWatcher() {
  yield takeLatest(RecTypes.GET_RECS_IMPORT, getRecsImports);
}

function* commitImportWatcher() {
  yield takeLatest(RecTypes.COMMIT_IMPORT, commitImport);
}

function* getRecsBusinessWatcher() {
  yield takeLatest(RecTypes.GET_RECS_BUSINESS, getRecsBusiness);
}

function* postRecsWatcher() {
  yield takeLatest(RecTypes.POST_RECS, postRecs);
}

function* generationRecsWatcher() {
  yield takeLatest(RecTypes.GENERATION_RECS, generationRecs);
}

function* putRecsWatcher() {
  yield takeLatest(RecTypes.PUT_RECS, putRecs);
}

function* approveRecWatcher() {
  yield takeLatest(RecTypes.APPROVE_REC, approveRec);
}

function* blockRecWatcher() {
  yield takeLatest(RecTypes.BLOCK_REC, blockRec);
}

function* refuseRecWatcher() {
  yield takeLatest(RecTypes.REFUSE_REC, refuseRec);
}

function* getPDFWatcher() {
  yield takeLatest(RecTypes.GET_PDF, getPDF);
}

export default function* rootSaga() {
  yield all([
    fork(getRecsWatcher),
    fork(postRecsWatcher),
    fork(generationRecsWatcher),
    fork(putRecsWatcher),
    fork(approveRecWatcher),
    fork(blockRecWatcher),
    fork(refuseRecWatcher),
    fork(getRecsImportWatcher),
    fork(getRecsBusinessWatcher),
    fork(commitImportWatcher),
    fork(getPDFWatcher),
  ]);
}
