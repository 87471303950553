import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { getToken, useQuery } from '../util';
import { Creators as RecoverPasswordActions } from '../store/ducks/RecoverPassword';
import CustomSnackbars from '../components/CustomSnackbars';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Required field')
    .min(8, 'Password must contain at least 8 characters'),
  password_confirmation: yup
    .string()
    .required('Required field')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

const RecoverPassword = props => {
  const dispatch = useDispatch();
  const query = useQuery(useLocation);
  const token = getToken();
  const { sentSuccess, error, loading } = useSelector(
    ({ recoverPassword }) => recoverPassword
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('error');

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    const token = query.get('token');
    if (!token) {
      setTypeMessage('error');
      setMessage('Error when registering new password');
      setOpen(true);
    } else {
      dispatch(RecoverPasswordActions.newPassword({ ...data, token }));
    }
  };

  useEffect(() => {
    dispatch(RecoverPasswordActions.clearState());
  }, []);

  useEffect(() => {
    if (error) {
      setTypeMessage('error');
      setMessage('Error when registering new password');
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (sentSuccess) {
      setTypeMessage('success');
      setMessage('Your password has been successfully reset');
      setOpen(true);
      setTimeout(() => props.history.push('/signin'), 4000);
    }
  }, [sentSuccess]);

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              src={require('assets/images/fullLogo.svg')}
              alt="SISGASREC"
              title="SISGASREC"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <Typography variant="h5">
              <IntlMessages id="appModule.Recover" />
            </Typography>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <TextField
                  type="password"
                  name="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  margin="normal"
                  className="mt-1 my-sm-3"
                  inputRef={register}
                />
                <p style={{ color: 'red' }}>{errors.password?.message}</p>
                <TextField
                  type="password"
                  name="password_confirmation"
                  label={<IntlMessages id="password_confirmation" />}
                  fullWidth
                  margin="normal"
                  className="my-sm-3"
                  inputRef={register}
                />
                <p style={{ color: 'red' }}>
                  {errors.password_confirmation?.message}
                </p>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <div>
                    <a href="/signin">return</a>
                  </div>
                  {loading ? (
                    <CircularProgress size={20} className="ml-4" />
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="appModule.RecoverSend" />
                    </Button>
                  )}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <CustomSnackbars
        open={open}
        setOpen={setOpen}
        text={message}
        type={typeMessage}
        // title={titleMessage}
      />

      {/* {message && NotificationManager.error(message)}
      <NotificationContainer /> */}
    </div>
  );
};

export default RecoverPassword;
