import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as SubstrateActions,
  Types as SubstrateTypes,
} from 'store/ducks/Substrate';
import api from '../../util/Api';

function* getSubstrate({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/substrate`, {
      params: payload,
    });
    if (status === 200) {
      yield put(SubstrateActions.getSubstrateListSuccess(data));
    } else {
      yield put(SubstrateActions.getSubstrateListFail());
    }
  } catch (err) {
    yield put(SubstrateActions.getSubstrateListFail());
  }
}

function* deleteSubstrate({ payload }) {
  try {
    const { status } = yield call(api.delete, `/substrate/${payload}`);
    if (status === 200) {
      yield put(SubstrateActions.deleteSubstrateSuccess());
    } else {
      yield put(SubstrateActions.deleteSubstrateFail());
    }
  } catch (err) {
    yield put(SubstrateActions.deleteSubstrateFail());
  }
}

function* createSubstrate({ payload }) {
  try {
    const { status } = yield call(api.post, '/substrate', payload);
    if (status === 200) {
      yield put(SubstrateActions.createSubstrateSuccess());
    } else {
      yield put(SubstrateActions.createSubstrateFail());
    }
  } catch (err) {
    yield put(SubstrateActions.createSubstrateFail());
  }
}

function* getIdSubstrate({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/substrate/${payload}`);
    if (status === 200) {
      yield put(SubstrateActions.getIdSubstrateSuccess(data));
    } else {
      yield put(SubstrateActions.getIdSubstrateFail());
    }
  } catch (err) {
    yield put(SubstrateActions.getIdSubstrateFail());
  }
}

function* updateSubstrate({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `substrate/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(SubstrateActions.updateSubstrateSuccess());
    } else {
      yield put(SubstrateActions.updateSubstrateFail());
    }
  } catch (err) {
    yield put(SubstrateActions.updateSubstrateFail());
  }
}

function* getSubstrateWatcher() {
  yield takeLatest(SubstrateTypes.GET_SUBSTRACT_LIST_REQUEST, getSubstrate);
}

function* deleteSubstrateWatcher() {
  yield takeLatest(SubstrateTypes.DELETE_SUBSTRACT_REQUEST, deleteSubstrate);
}

function* createSubstrateWatcher() {
  yield takeLatest(SubstrateTypes.CREATE_SUBSTRACT_REQUEST, createSubstrate);
}

function* getIdSubstrateWatcher() {
  yield takeLatest(SubstrateTypes.GET_ID_SUBSTRACT_REQUEST, getIdSubstrate);
}

function* updateSubstrateWatcher() {
  yield takeLatest(SubstrateTypes.UPDATE_SUBSTRACT_REQUEST, updateSubstrate);
}

export default function* rootSaga() {
  yield all([
    fork(getSubstrateWatcher),
    fork(deleteSubstrateWatcher),
    fork(createSubstrateWatcher),
    fork(getIdSubstrateWatcher),
    fork(updateSubstrateWatcher),
  ]);
}
